export const FirebaseConfig = {
	"projectId": "taxifret-v-firebase",
	"appId": "1:1045583936407:web:0fa08b18ab51d7de92a2d2",
	"databaseURL": "https://taxifret-v-firebase-default-rtdb.firebaseio.com",
	"storageBucket": "taxifret-v-firebase.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyC2bn9Yj3otwzEvs91C2UbRuPEYnNSxZ5E",
	"authDomain": "taxifret-v-firebase.firebaseapp.com",
	"messagingSenderId": "1045583936407",
	"measurementId": "G-VDDH318HMP"
};